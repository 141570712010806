import React from 'react'
import { Router } from '@reach/router'
import _ from 'lodash'
import CareersController from '../screens/careers'
import CareersDetailController from '../screens/careers-detail'

const CareersPage = (props) => {
  const path = _.get(props, 'pageContext.locale') === 'en' ? '/careers/' : `${_.get(props, 'pageContext.locale')}/careers/`
  return (
    <Router>
      <CareersDetailController {...props} path={`${path}:slug`} />
      <CareersController {...props} path={`${path}`} />
    </Router>
  )
}

export default CareersPage
