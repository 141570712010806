import _ from 'lodash'
import React from 'react'

const TwoColumnsOpening = ({ data }) => {
  const content = _.get(data, 'column[0]')
  return (
    <div className="twoColumns__container">
      <div className="twoColumns__head">
        <h3>{content?.title}</h3>
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: content?.copy }}
        className="twoColumns__content rich-text"/>

    </div>
  )
}

export default TwoColumnsOpening
