import React from 'react'

const FramePictures = ({ data }) => (
  <div className="framePictures__container">
    <div className="framePictures__frame framePictures__frame--left">

      <img src={data.image.url} alt={data.image.alt} />

    </div>
    <div className="framePictures__frame framePictures__frame--right">

      <img src={data.image_second.url} alt={data.image_second.alt} />

    </div>

  </div>
)

export default FramePictures
