import React, { useEffect, useState } from 'react'
import makeRequest from '../../helpers/make-request'
import CareersDetailView from './careers-detail-view'
import { useTranslation } from 'react-i18next'

const CareersDetailController = ({ slug }) => {
  const [loading, setLoading] = useState(true)
  const [dataCareersDetail, setDataCareersDetail] = useState([])
  const controller = new AbortController()
  const { i18n } = useTranslation();

  // catch with useEffect so the data will be contained
  const getCareersDetailData = async () => {
    setLoading(true)
    const fields = 'id,title,slug,acf'
    const headers = {
      'Content-Type': 'application/json',
    }

    // fetch the data with makerequest
    makeRequest({
      headers,
      endPoint: 'careers',
      signal: controller.signal,
      params: {
        fields,
        slug,
        lang: i18n.language
      },
    }).then((resp) => {
      setDataCareersDetail(resp.data[0])
      setLoading(false)
    })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }
  useEffect(() => {
    getCareersDetailData()
    return () => {
      controller.abort()
    }
  }, [])

  const viewProps = {
    data: dataCareersDetail,
    loading,
  }
  return (
    <CareersDetailView {...viewProps}/>
  )
}

export default CareersDetailController
